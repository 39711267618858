import ls from 'localstorage-slim';

$(() => {
  const searchCriteriaHistories = ls.get('searchCriteriaHistories') || [];
  if (searchCriteriaHistories.length === 0) {
    return;
  }
  appendSearchCriteriaDocument(searchCriteriaHistories);
});
function setSearchCriteriaHistory(keyword) {
  if (keyword === '') {
    return;
  }
  const searchCriteriaHistories = ls.get('searchCriteriaHistories') || [];
  const val = {
    keyword: keyword.toString(),
    filters: [], // MEMO: filtersの登録はキーワード検索で絞り込みが実装されたら登録する
  };
  const index = $.inArray(
    val.keyword,
    searchCriteriaHistories.map(v => v.keyword),
  );
  if (index !== -1) {
    searchCriteriaHistories.splice(index, 1);
  }
  searchCriteriaHistories.unshift(val);
  if (searchCriteriaHistories.length > 10) {
    searchCriteriaHistories.pop();
  }
  ls.set('searchCriteriaHistories', searchCriteriaHistories);
}

function removeSearchCriteriaHistoriesAt(index) {
  const searchCriteriaHistories = ls.get('searchCriteriaHistories') || [];
  searchCriteriaHistories.splice(index, 1);
  if (searchCriteriaHistories.length === 0) {
    $('div.search-criteria-histories-wrapper').css('display', 'none');
  }
  ls.set('searchCriteriaHistories', searchCriteriaHistories);
  $('div.search-criteria-histories-wrapper').html('');
  if (searchCriteriaHistories.length > 0) {
    appendSearchCriteriaDocument(searchCriteriaHistories);
  }
  getPopularKeywords();
  window.Geenie.ClickTracking.bindTracking();
}

function searchCriteriaRemoveIcon(index) {
  const clickTrackingCategory = $(
    '[data-search-criteria-history-tracking-category]',
  ).data().searchCriteriaHistoryTrackingCategory;
  const clickTrackingLabel = {
    module: 'search_criteria_history',
    parts: 'item_delete',
    position: index,
  };
  return `
    <i class="search-criteria-remove-icon"
      data-index=${index}
      data-click-tracking-category=${JSON.stringify(clickTrackingCategory)}
      data-click-tracking-label=${JSON.stringify(clickTrackingLabel)}>
      <svg
        width="10"
        height="10"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="0.73901"
          y="13.989"
          width="18.8903"
          height="2"
          rx="1"
          transform="rotate(-45 0.73901 13.989)"
          fill="#9D9D9D"
        />
        <rect
          x="2.03628"
          y="0.622314"
          width="18.981"
          height="2"
          rx="1"
          transform="rotate(45 2.03628 0.622314)"
          fill="#9D9D9D"
        />
      </svg>
    </i>`;
}

function appendSearchCriteriaDocument(searchCriteriaHistories) {
  let html = '';
  const clickTrackingCategory = $(
    '[data-search-criteria-history-tracking-category]',
  ).data().searchCriteriaHistoryTrackingCategory;
  html += '<p class="search-criteria-history-title">検索履歴</p>';
  html += '<ul>';
  for (let i = 0; i < searchCriteriaHistories.length; i += 1) {
    const clickTrackingLabel = {
      module: 'search_criteria_history',
      parts: 'item',
      position: i,
    };
    html += '<li class="search-criteria-item">';
    html += searchCriteriaRemoveIcon(i);
    html += '<a data-keyword="';
    html += searchCriteriaHistories[i].keyword;
    html += '" class="search-criteria-link" href="/discover/projects/?keyword=';
    html += encodeURIComponent(searchCriteriaHistories[i].keyword);
    html += '" data-click-tracking-category=';
    html += JSON.stringify(clickTrackingCategory);
    html += ' data-click-tracking-label=';
    html += JSON.stringify(clickTrackingLabel);
    html += '>';
    html += searchCriteriaHistories[i].keyword;
    html += '</a></li>';
  }
  html += '</ul>';
  $('div.search-criteria-histories-wrapper').html(html);
}

function appendPopularKeywordDocument(popularKeywords) {
  if ($('#search_form').find('.search-popular-keywords-title').length > 0) {
    return;
  }
  const clickTrackingCategory = $(
    '[data-search-criteria-history-tracking-category]',
  ).data().searchCriteriaHistoryTrackingCategory;
  let html = '';
  html += '<p class="search-popular-keywords-title">人気のキーワード</p>';
  html += '<ul class="search-popular-keywords-wrapper">';
  for (let i = 0; i < popularKeywords.length; i += 1) {
    html += '<li class="search-popular-keywords-item">';
    html += '<a data-keyword="';
    html += popularKeywords[i];
    html +=
      '" class="search-popular-keywords-link" href="/discover/projects/?keyword=';
    html += encodeURIComponent(popularKeywords[i]);
    html += '" data-click-tracking-category=';
    html += JSON.stringify(clickTrackingCategory);
    const clickTrackingLabel = {
      module: 'search_popular_keyword',
      parts: 'item',
      position: i,
    };
    html += ' data-click-tracking-label=';
    html += JSON.stringify(clickTrackingLabel);
    html += '">';
    html += popularKeywords[i];
    html += '</a></li>';
  }
  html += '</ul>';
  $('div.search-criteria-histories-wrapper').append(html);
  window.Geenie.ClickTracking.bindTracking();
  if ($('#search_form').find('.search-criteria-history-title').length > 0) {
    $('.search-popular-keywords-title').css('margin-top', '32px');
  }
}

$('#search_form').on('submit', e => {
  e.preventDefault();
  const keyword = $('#keyword').val();
  setSearchCriteriaHistory(keyword);
  setPopularKeywords(keyword);
  $('#search_form')[0].submit();
});

$('#keyword').on('input', function () {
  const value = $(this).val();
  if (value) {
    $('div.search-criteria-histories-wrapper').css('display', 'none');
  }
});

let searchCriteriaHistoryClick = false;
$('#keyword').on('focus', function () {
  const value = $(this).val();
  if (value !== '') {
    $('div.search-criteria-histories-wrapper').css('display', 'none');
    return;
  }
  const searchCriteriaHistories = ls.get('searchCriteriaHistories') || [];
  if (searchCriteriaHistories.length > 0) {
    appendSearchCriteriaDocument(searchCriteriaHistories);
    $('div.search-criteria-histories-wrapper').css('display', 'block');
  }
  getPopularKeywords();
});

$('#keyword').on('blur', e => {
  e.preventDefault();
  if (searchCriteriaHistoryClick) {
    searchCriteriaHistoryClick = false;
    return;
  }
  $('div.search-criteria-histories-wrapper').css('display', 'none');
});

$('div.search-criteria-histories-wrapper').on('mousedown', 'i', () => {
  searchCriteriaHistoryClick = true;
});
$('div.search-criteria-histories-wrapper').on('click', 'i', function () {
  const index = $(this).data('index');
  removeSearchCriteriaHistoriesAt(index);
});

$('div.search-criteria-histories-wrapper').on('mousedown', 'a', () => {
  searchCriteriaHistoryClick = true;
});
$('div.search-criteria-histories-wrapper').on('click', 'a', function () {
  const keyword = $(this).data('keyword');
  setSearchCriteriaHistory(keyword);
});
$('div.search-criteria-histories-wrapper').on('mousedown', () => {
  searchCriteriaHistoryClick = true;
});

$('div.search-criteria-histories-wrapper').on('blur', () => {
  $('div.search-criteria-histories-wrapper').css('display', 'none');
});

function getPopularKeywords() {
  $.ajax({
    type: 'GET',
    url: '/api/popularkeyword/',
    cache: false,
    dataType: 'json',
  }).success(data => {
    if (data) {
      appendPopularKeywordDocument(data);
      $('div.search-criteria-histories-wrapper').css('display', 'block');
    }
  });
}

function setPopularKeywords(keyword) {
  $.ajax({
    type: 'POST',
    url: '/api/popularkeyword/',
    cache: false,
    dataType: 'json',
    data: {
      keyword,
      fuel_csrf_token: window.fuel_csrf_token(),
    },
  });
}
